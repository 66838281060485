














































































































































































































































































































































































































































































































































































































































































































































































.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .form-inline {
    width: 45%;

    .el-form-item__content {

      // width: 100%;
      p {
        width: 15rem;
      }
    }
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }

  .el-cascader {
    width: 100%;
  }

  .el-progress {
    width: 200px;
  }

  .chapterl-trees {
    padding: 15px 15px 15px 0;

    .el-tree-node__content {
      height: 36px;
    }

    .draggable-item {
      margin-left: 5px;

      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }

      margin-left: 15px;

      &>em,
      .handle>em {
        font-size: 14px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .chapteru-lists {
    padding: 15px 15px 15px 0;

    .el-divider--horizontal {
      margin: 5px;
    }

    .cl-subhint {
      display: flex;

      span {
        font-size: 14px;
        color: #666;

        &:nth-child(2) {
          margin-left: 15px;
        }
      }

      em {
        margin: 0 5px;
      }
    }

    .cl-btns {
      i {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }

  .pageForm-btns {
    padding: 15px 0;
    text-align: center;

    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}

.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}

.detail {
  .ellipsis {
    display: inline-block;
    width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}

.doedit {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #6676ff;
  }
}

.imgBoxs {
  display: flex;

  >img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
